import Axios, { AxiosStatic } from 'axios';
import { Links } from '../data/links';
import { Data } from '../reducers/data';
import { ErrorLogger } from './error-handling';

export interface DataResponseParams {
  data: Data;
  status: string;
  message?: string;
}
export interface DataParams {
  [index: string]: string | { [index: string]: string } | { [index: string]: string }[] | { [index: string]: { [index: string]: string } };
}
interface DataOrderParams extends DataParams {
  positions: { [index: string]: string }[];
  additional_costs: { [index: string]: string }[];
}

export class DataServiceClass {
  constructor(public transport: AxiosStatic = Axios) { }

  /**
   * Data request to HuM system
   *
   * @param {DataParams} products
   * @returns Promise<DataResponseParams>
   * @memberof DataServiceClass
   */
  getData(params: DataParams | DataOrderParams, endpoint: string, token: string): Promise<DataResponseParams> {
    return new Promise((resolve, reject) => {
      this.transport
        .post(endpoint,
          JSON.stringify(params), {
            maxRedirects: 0,
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Basic ${token}`,
            }
          })
        .then(res => {
          const data: DataResponseParams = res.data;
          return resolve(data);
        })
        .catch(res => {
          ErrorLogger.logErrorRequest(endpoint, params as DataParams, res.response.data, res.response.status, token);
          return resolve(res);
        });
    });
  }


  getPurchase(params: DataParams = {}, token: string): Promise<DataResponseParams> {
    return this.getData(params, Links.purchases, token);
  }

  getSales(params: DataParams = {}, token: string): Promise<DataResponseParams> {
    return this.getData(params, Links.sales, token);
  }

  getPurchaseOrders(params: DataParams = {}, token: string): Promise<DataResponseParams> {
    return this.getData(params, Links.purchase_orders, token);
  }

  getSalesOrders(params: DataParams = {}, token: string): Promise<DataResponseParams> {
    return this.getData(params, Links.sale_orders, token);
  }

  getSapOrders(params: DataParams = {}, token: string): Promise<DataResponseParams> {
    return this.getData(params, Links.getSapOrders, token);
  }

  getBillingSapOrders(params: DataParams = {}, token: string): Promise<DataResponseParams> {
    return this.getData(params, Links.getBillingSapOrders, token);
  }

  getDeliveringSapOrders(params: DataParams = {}, token: string): Promise<DataResponseParams> {
    return this.getData(params, Links.getDeliveringSapOrders, token);
  }

  getCustomers(params: DataParams = {}, token: string): Promise<DataResponseParams> {
    return this.getData(params, Links.customers, token);
  }

  getCustomerRepresentative(params: DataParams = {}, token: string): Promise<DataResponseParams> {
    return this.getData(params, Links.getCustomerRepresentative, token);
  }

  getMetagata(token: string): Promise<DataResponseParams> {
    return new Promise((resolve, reject) => {
      this.transport
        .get(Links.metadata, {
          headers: {
            'Authorization': `Basic ${token}`,
          }
        })
        .then(res => {
          const data: DataResponseParams = res.data;
          return resolve(data);
        })
        .catch(res => {
          ErrorLogger.logErrorRequest(Links.metadata, {}, res.response.data, res.response.status, token);
          return resolve(res);
        });
    });
  }

  createPurchase(params: DataOrderParams, token: string): Promise<DataResponseParams> {
    return this.getData(params, Links.create_purchase, token);
  }

  creatSales(params: DataOrderParams, token: string): Promise<DataResponseParams> {
    return this.getData(params, Links.create_sales, token);
  }

  getDashboard(params: DataParams = {}, token: string): Promise<DataResponseParams> {
    return this.getData(params, Links.dashboard, token);
  }

  exportData(link: string, filename: string, params: DataParams = {}, token: string): Promise<DataResponseParams> {
    return new Promise((resolve, reject) => {
      this.transport
        .get(link, {
          params,
          responseType: 'blob',
          headers: {
            'Authorization': `Basic ${token}`,
          }
        })
        .then(res => {
          const data: DataResponseParams = res.data;
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();

          return resolve(data);
        })
        .catch(res => {
          ErrorLogger.logErrorRequest(link, {}, res.response.data, res.response.status, token);
          return resolve(res);
        });
    });
  }

  downloadReport(filename: string, token: string): Promise<DataResponseParams> {
    return new Promise((resolve, reject) => {
      this.transport
        .get(Links.downloadReport + filename, {
          responseType: 'blob',
          headers: {
            'Authorization': `Basic ${token}`,
          }
        })
        .then(res => {
          const data: DataResponseParams = res.data;
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();

          return resolve(data);
        })
        .catch(res => {
          ErrorLogger.logErrorRequest(Links.downloadReport, {}, res.response.data, res.response.status, token);
          return resolve(res);
        });
    });
  }

  getPurchaseOrderDetails(params: DataParams, token: string): Promise<DataResponseParams> {
    return this.getData(params, Links.details_purchase, token);
  }
  getSalesOrderDetails(params: DataParams, token: string): Promise<DataResponseParams> {
    return this.getData(params, Links.details_sales, token);
  }

  deleteOrderPurchase(params: DataParams, token: string): Promise<DataResponseParams> {
    return this.getData(params, Links.cancel_purchase, token);
  }
  deleteOrderSales(params: DataParams, token: string): Promise<DataResponseParams> {
    return this.getData(params, Links.cancel_sales, token);
  }

  deleteDeliveryPurchase(params: DataParams, token: string): Promise<DataResponseParams> {
    return this.getData(params, Links.cancel_purchase_delivery, token);
  }
  deleteDeliverySales(params: DataParams, token: string): Promise<DataResponseParams> {
    return this.getData(params, Links.cancel_sales_delivery, token);
  }

  deleteInvoicePurchase(params: DataParams, token: string): Promise<DataResponseParams> {
    return this.getData(params, Links.cancel_purchase_invoice, token);
  }
  deleteInvoiceSales(params: DataParams, token: string): Promise<DataResponseParams> {
    return this.getData(params, Links.cancel_sales_invoice, token);
  }

  changeSalesOrder(params: DataOrderParams, token: string): Promise<DataResponseParams> {
    return this.getData(params, Links.change_sales, token);
  }
  changePurchaseOrder(params: DataOrderParams, token: string): Promise<DataResponseParams> {
    return this.getData(params, Links.change_purchase, token);
  }

  editMaterial(params: DataParams, token: string): Promise<DataResponseParams> {
    return this.getData(params, Links.editMaterial, token);
  }
  addMaterial(params: DataParams, token: string): Promise<DataResponseParams> {
    return this.getData(params, Links.addMaterial, token);
  }

  getReports(params: DataParams = {}, token: string): Promise<DataResponseParams> {
    return this.getData(params, Links.getReports, token);
  }

  getLogs(params: DataParams = {}, token: string): Promise<DataResponseParams> {
    return this.getData(params, Links.logs, token);
  }

  updateSettings(params: DataParams = {}, token: string): Promise<DataResponseParams> {
    return this.getData(params, Links.settingsUpdate, token);
  }
}
