import * as React from 'react';
import { connect } from 'react-redux';
import { RootState, RootAction } from '../../../store/types';
import { getOrderDetailsTrigger, } from '../../../selectors';
import { canselSale, unlockSale, lockSale } from '../../../actions/trading';
import { SALES_POSITION_CONTEXT_MENU_ID } from '../../../data/constants';
import { getOrderSalesDetails, canselSalesOrder, changeSalesOrder, lockOrderSalePositions, unlockOrderSalePositions, loadSalesHistory, resetHistory, setOrderDetailsTrigger, resetOrderDetailsTrigger, resetOrderDetails, canselSalesDelivery, canselSalesInvoice } from '../../../actions/data';
import { connectMenu } from 'react-contextmenu';
import PositionContextMenu from '../contextmenu';
import SalesTable from './salesTable';

const SEARCH_TYPE = "SALE";

const ConnectedMenu = connectMenu(SALES_POSITION_CONTEXT_MENU_ID)(PositionContextMenu);

export interface OrderTabProps {
    title?: string;
    showPositionOrderDetailsTrigger?: { show: boolean, id: string };

    onPositionDelete?: (id: string) => void;
    onPositionLock?: (id: string) => void;
    onPositionUnlock?: (id: string) => void;
    onGetPositionOrderDetails?: (id: string) => void;
    onOrderDelete?: (id: string) => void;
    onDeliveryDelete?: (id: string) => void;
    onInvoiceDelete?: (id: string) => void;
    onOrderChangesSave?: (id: string) => void;
    onOrderLock?: (ids: string[]) => void;
    onOrderUnLock?: (ids: string[]) => void;
    onHistoryLoad?: (ids: string[], orderId?: string) => void;
    onRemovePositionOrderDetailsTrigger?: () => void;
}

export class OrderTab extends React.PureComponent<OrderTabProps, {}> {
    render() {
        return [
            <SalesTable title={this.props.title}/>,
            <ConnectedMenu
                key="menu"
                removeItem={this.props.onPositionDelete}
                unlockItem={this.props.onPositionUnlock}
                lockItem={this.props.onPositionLock}
                getDetails={this.props.onGetPositionOrderDetails}
                deleteOrder={this.props.onOrderDelete}
                deleteDelivery={this.props.onDeliveryDelete}
                deleteInvoice={this.props.onInvoiceDelete}
                saveOrderChanges={this.props.onOrderChangesSave}
                lockOrderPositions={this.props.onOrderLock}
                unlockOrderPositions={this.props.onOrderUnLock}
                isPurchase={false}
                historyLoad={this.props.onHistoryLoad}
                showPositionOrderDetailsTrigger={this.props.showPositionOrderDetailsTrigger}
                removePositionOrderDetailsTrigger={this.props.onRemovePositionOrderDetailsTrigger}
            />
        ]
    }
}
const mapStateToProps = (
    state: RootState,
    ownProps: OrderTabProps
) => {
    const trigger = getOrderDetailsTrigger(state);
    return {
        showPositionOrderDetailsTrigger: trigger && {...trigger, show: trigger.table === SEARCH_TYPE},
    }
};

const mapDispatchToProps = (
    dispatch: (action: RootAction) => void,
    ownProps: OrderTabProps
) => {
    return {
        onPositionDelete(id: string) {
            dispatch(canselSale(id));
        },
        onPositionLock(id: string) {
            dispatch(lockSale(id, true));
        },
        onPositionUnlock(id: string) {
            dispatch(unlockSale(id, true));
        },
        onGetPositionOrderDetails(id: string) {
            dispatch(resetOrderDetails());
            dispatch(getOrderSalesDetails(id));
        },
        onOrderDelete(id: string) {
            dispatch(canselSalesOrder(id));
        },
        onDeliveryDelete(id: string) {
            dispatch(canselSalesDelivery(id));
        },
        onInvoiceDelete(id: string) {
            dispatch(canselSalesInvoice(id));
        },
        onOrderChangesSave(id: string) {
            dispatch(changeSalesOrder(id));
        },
        onOrderLock(ids: string[]) {
            dispatch(lockOrderSalePositions(ids));
        },
        onOrderUnLock(ids: string[]) {
            dispatch(unlockOrderSalePositions(ids));
        },
        onHistoryLoad(ids: string[], orderId: string) {
            dispatch(resetHistory());
            dispatch(loadSalesHistory(ids, orderId));
        },
        onRemovePositionOrderDetailsTrigger(){
            dispatch(resetOrderDetailsTrigger());
        }
    };
};


export const SalesBarContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(OrderTab);

export default SalesBarContainer;
