import * as React from 'react';
import { ContextMenu, MenuItem } from "react-contextmenu"
import { i18nContext } from '../../language';
import { default as Popup } from './popup/details-order-popup';
import { default as HistoryPopup } from './popup/history-popup';
import { componentWillAppendToBody } from 'react-append-to-body';
import ModalPopup from './popup/modal-popup';

const Textes = {
    change: "Change Position",
    remove: "Remove Position",
    unlock: "Unlock Position",
    lock: "Lock Position",
    order_details: "Show/Edit Order Details",
    history: "Show Position History",
    cancel: "Cancel",
    removeText: "Do you really want to remove this position?",
}

interface PositionContextMenuProps {
    id: string;
    trigger: { positionId: string, locked: boolean };
    isPurchase?: boolean;
    showPositionOrderDetailsTrigger?: { show: boolean, id: string };

    removeItem?: (id: string) => void;
//    lockItem?: (id: string) => void;
//    unlockItem?: (id: string) => void;
    getDetails?: (id: string) => void;
    deleteOrder?: (id: string) => void;
    deleteDelivery?: (id: string) => void;
    deleteInvoice?: (id: string) => void;
    saveOrderChanges?: (id: string) => void;
    lockOrderPositions?: (ids: string[]) => void;
    unlockOrderPositions?: (ids: string[]) => void;
    historyLoad?: (ids: string[], orderId?: string) => void;
    removePositionOrderDetailsTrigger?: () => void;
}

const AppendedPopup = componentWillAppendToBody(Popup);
const AppendedHistoryPopup = componentWillAppendToBody(HistoryPopup);

class PositionContextMenu extends React.Component<PositionContextMenuProps, {}> {
    trigger: { positionId?: string, locked?: boolean }
    state = {
        showRemoveConfirm: false,
        showOrderDetailsPopup: false,
        showHistoryPopup: false,
    }
    constructor(props: PositionContextMenuProps) {
        super(props);
        this.removeItem = this.removeItem.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
        this.loadPositionHistory = this.loadPositionHistory.bind(this);
        this.onHistoryPopupToggle = this.onHistoryPopupToggle.bind(this);
        this.onOrderDetailsPopupToggle = this.onOrderDetailsPopupToggle.bind(this);
    }

    handleRemove() {
        this.setState({
            showRemoveConfirm: !this.state.showRemoveConfirm
        });
    }
    removeItem() {
        this.handleRemove();
        this.props.removeItem(this.trigger.positionId);
    }

    componentDidUpdate() {
        if (this.props.trigger && this.props.trigger.positionId) {
            this.trigger = this.props.trigger;
        } else if (
            !this.state.showOrderDetailsPopup &&
            this.props.showPositionOrderDetailsTrigger &&
            this.props.showPositionOrderDetailsTrigger.show &&
            this.props.showPositionOrderDetailsTrigger.id
        ) {
            if(!this.trigger){
                this.trigger = {};
            }
            this.trigger.positionId = this.props.showPositionOrderDetailsTrigger.id;
            this.onOrderDetailsPopupToggle();
            this.props.removePositionOrderDetailsTrigger();
        }
    }
    onOrderDetailsPopupToggle() {
        this.setState({
            showOrderDetailsPopup: !this.state.showOrderDetailsPopup,
        },
            () => {
                const parentElement = document.body;
                if (!parentElement) {
                    return;
                }

                if (this.state.showOrderDetailsPopup) {
                    this.props.getDetails(this.trigger.positionId);
                    parentElement.classList.add('popup-showed');
                } else {
                    parentElement.classList.remove('popup-showed');
                }
            }
        );
    }
    loadPositionHistory() {
        this.props.historyLoad([this.trigger.positionId]);
    }
    onHistoryPopupToggle() {
        this.setState({
            showHistoryPopup: !this.state.showHistoryPopup,
        },
            () => {
                const parentElement = document.body;
                if (!parentElement) {
                    return;
                }

                if (this.state.showHistoryPopup) {
                    this.loadPositionHistory();
                    parentElement.classList.add('popup-showed');
                } else {
                    parentElement.classList.remove('popup-showed');
                }
            }
        );
    }

    render() {
        return (
            <i18nContext.Consumer>
                {i18n => [
                    <ContextMenu key="menu" id={this.props.id} className="card py-1">
                        {this.props.trigger && [
                            <MenuItem
                                key="order-details"
                                attributes={{ className: "pointer px-3 py-1 contextmenu-item" }}
                                onClick={e => this.onOrderDetailsPopupToggle()}
                            >
                                {i18n.t(Textes.order_details)}
                            </MenuItem>,
                            <MenuItem
                                key="history"
                                attributes={{ className: "pointer px-3 py-1 contextmenu-item" }}
                                onClick={e => this.onHistoryPopupToggle()}
                            >
                                {i18n.t(Textes.history)}
                            </MenuItem>,
/*                            ...(this.props.trigger && this.props.trigger.locked ? [
                                <MenuItem
                                    key="unlock"
                                    attributes={{ className: "pointer px-3 py-1 contextmenu-item" }}
                                    onClick={e => this.props.unlockItem(this.trigger.positionId)}
                                >
                                    {i18n.t(Textes.unlock)}
                                </MenuItem>
                            ] : [
                                    <MenuItem
                                        key="lock"
                                        attributes={{ className: "pointer px-3 py-1 contextmenu-item" }}
                                        onClick={e => this.props.lockItem(this.trigger.positionId)}
                                    >
                                        {i18n.t(Textes.lock)}
                                    </MenuItem>
                                ]),

 */
                            <MenuItem
                                key="remove"
                                attributes={{ className: "pointer px-3 py-1 contextmenu-item" }}
                                onClick={this.handleRemove}
                            >
                                {i18n.t(Textes.remove)}
                            </MenuItem>
                        ]}
                    </ContextMenu>,
                    <ModalPopup
                        key="modal-popup"
                        showModal={this.state.showRemoveConfirm}
                        submitRemove={this.removeItem}
                        closePopup={this.handleRemove}
                        textes={{
                            title: i18n.t(Textes.remove),
                            confirm: i18n.t(Textes.remove),
                            cancel: i18n.t(Textes.cancel),
                            text: i18n.t(Textes.removeText),
                        }}
                    />,
                    <AppendedPopup
                        key="detail-popup"
                        isPurchase={this.props.isPurchase}
                        showPopup={this.state.showOrderDetailsPopup}
                        closePopup={this.onOrderDetailsPopupToggle}
                        getDetails={this.props.getDetails}
                        deleteOrder={this.props.deleteOrder}
                        deleteDelivery={this.props.deleteDelivery}
                        deleteInvoice={this.props.deleteInvoice}
                        saveOrderChanges={this.props.saveOrderChanges}
                        lockOrderPositions={this.props.lockOrderPositions}
                        unlockOrderPositions={this.props.unlockOrderPositions}
                        loadHistory={this.props.historyLoad}
                    />,
                    <AppendedHistoryPopup
                        key="history-popup"
                        showPopup={this.state.showHistoryPopup}
                        loadHistory={this.loadPositionHistory}
                        closePopup={this.onHistoryPopupToggle}
                        hideUsers
                    />
                ]}
            </i18nContext.Consumer>
        );
    };
}

export default PositionContextMenu;