import Axios, { AxiosStatic } from 'axios';
import { Links } from '../data/links';
import { Data } from '../reducers/data';
import { ErrorLogger } from './error-handling';
import { DataParams } from './data';

interface TradingParams {
    id?: string;
    user?: string;
    language?: string;
    sale_order_id?: string;
    purchase_order_id?: string;
    sale_positions?: string[] | { [index: string]: number };
    purchase_positions?: string[] | { [index: string]: number };
    invoice_date?: string;
}

export interface TradingResponseParams {
    status: string;
    message?: string;
    data?: Data | { [index: string]: Data };
}

export class TradingServiceClass {
    constructor(public transport: AxiosStatic = Axios) { }

    getTrading(params: TradingParams, endpoint: string, token: string): Promise<TradingResponseParams> {
        return new Promise((resolve, reject) => {
            this.transport
                .post(endpoint,
                    JSON.stringify(params), {
                        maxRedirects: 0,
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Basic ${token}`,
                        }
                    })
                .then(res => {
                    const data: TradingResponseParams = res.data;
                    return resolve(data);
                })
                .catch(res => {
                  ErrorLogger.logErrorRequest(endpoint, params as DataParams, res.response.data, res.response.status, token);
                  return resolve(res);
                });
        });
    }

    lockPurchase(params: TradingParams = {}, token: string): Promise<TradingResponseParams> {
        return this.getTrading(params, Links.tradingPurchaseLock, token);
    }
    unlockPurchase(params: TradingParams = {}, token: string): Promise<TradingResponseParams> {
        return this.getTrading(params, Links.tradingPurchaseUnlock, token);
    }
    cancelPurchase(params: TradingParams = {}, token: string): Promise<TradingResponseParams> {
        return this.getTrading(params, Links.tradingPurchaseCancel, token);
    }

    lockSale(params: TradingParams = {}, token: string): Promise<TradingResponseParams> {
        return this.getTrading(params, Links.tradingSaleLock, token);
    }
    unlockSale(params: TradingParams = {}, token: string): Promise<TradingResponseParams> {
        return this.getTrading(params, Links.tradingSaleUnLock, token);
    }
    cancelSale(params: TradingParams = {}, token: string): Promise<TradingResponseParams> {
        return this.getTrading(params, Links.tradingSaleCancel, token);
    }

    unlockAll(params: TradingParams = {}, token: string): Promise<TradingResponseParams> {
        return this.getTrading(params, Links.tradingUnlockAll, token);
    }
    lockAll(params: TradingParams = {}, token: string): Promise<TradingResponseParams> {
        return this.getTrading(params, Links.tradingLockAll, token);
    }

    tradingMatch(params: TradingParams = {}, token: string): Promise<TradingResponseParams> {
        return this.getTrading(params, Links.tradingMatch, token);
    }
    tradingInventory(params: TradingParams = {}, token: string): Promise<TradingResponseParams> {
        return this.getTrading(params, Links.tradingInventory, token);
    }
    tradingPnL(params: TradingParams = {}, token: string): Promise<TradingResponseParams> {
        return this.getTrading(params, Links.tradingPnL, token);
    }

    sentToSap(params: TradingParams = {}, token: string): Promise<TradingResponseParams> {
        return this.getTrading(params, Links.sapCreateOrder, token);
    }
    sapBilling(params: TradingParams = {}, token: string): Promise<TradingResponseParams> {
        return this.getTrading(params, Links.sapBillingOrder, token);
    }
    sapDelivering(params: TradingParams = {}, token: string): Promise<TradingResponseParams> {
        return this.getTrading(params, Links.sapDeliveringOrder, token);
    }

    getHisory(params: TradingParams, token: string): Promise<TradingResponseParams> {
      return this.getTrading(params, Links.history, token);
    }
}
