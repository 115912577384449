import Axios, { AxiosStatic } from 'axios';
import { Links } from '../data/links';
import { Data } from '../reducers/data';
import { getDate } from '../helpers/helper';
import { DataParams, DataResponseParams } from './data';

interface ErrorRequestParams {
  [index: string]: string | DataParams;
}

export class ErrorLoggerServiceClass {
  constructor(public transport: AxiosStatic = Axios) { }

  /**
   * Log error to HuM system
   *
   * @param {DataParams} products
   * @returns Promise<DataResponseParams>
   * @memberof ErrorLoggerServiceClass
   */
  logError(params: ErrorRequestParams, token: string): Promise<DataResponseParams> {
    return new Promise((resolve, reject) => {
      this.transport
        .post(Links.logError,
          JSON.stringify(params), {
            maxRedirects: 0,
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Basic ${token}`,
            }
          })
        .then(res => {
          const data: DataResponseParams = res.data;
          return resolve(data);
        })
        .catch(resolve);
    });
  }
  logErrorRequest(endpoint: string, request_body: DataParams, response_body: string, code: string, token: string) {
    return this.logError({endpoint, request_body, response_body, code}, token);
  }
}

export const ErrorLogger = new ErrorLoggerServiceClass();