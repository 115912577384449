import Axios, { AxiosStatic } from 'axios';
import { Links } from '../data/links';
import { ErrorLogger } from './error-handling';
import { DataParams } from './data';
import { StorageSession } from './storage';

interface LogoutResponseParams {
  status: string;
  message?: string;
}

interface LoginResponseParams {
  status: string;
  user: string;
  role: string;
  token: string;
  message?: string;
}
export class SessionServiceClass {
  constructor(public transport: AxiosStatic = Axios) { }

  /**
   * Login user to HuM system
   *
   * @param {DataParams} params
   * @returns Promise<LoginResponseParams>
   * @memberof SessionServiceClass
   */
  loginReques(params: DataParams, token: string): Promise<LoginResponseParams> {
    return new Promise((resolve, reject) => {
      this.transport
        .post(Links.login,
          JSON.stringify(params), {
            maxRedirects: 0,
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Basic ${token}`,
            }
          })
        .then(res => {
          if (res.data.redirect_link) {
            window.location.replace(res.data.redirect_link);
            return resolve(null);
          }

          const data: LoginResponseParams = res.data;
          return resolve(data);
        })
        .catch(res => {
          ErrorLogger.logErrorRequest(Links.login, params, res.response.data, res.response.status, token);
          return resolve(res);
        });
    });
  }

  /**
   * Logout user from HuM system
   *
   * @param {DataParams} params
   * @returns Promise<LogoutResponseParams>
   * @memberof SessionServiceClass
   */
  logoutReques(params: DataParams, token: string): Promise<LogoutResponseParams> {
    return new Promise((resolve, reject) => {
      this.transport
        .post(Links.logout,
          JSON.stringify(params), {
            maxRedirects: 0,
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Basic ${token}`,
            }
          })
        .then(res => {
          const data: LogoutResponseParams = res.data;
          if (data.status !== "OK") {
            return reject(data.message);
          }
          return resolve(data);
        })
        .catch(res => {
          ErrorLogger.logErrorRequest(Links.logout, params, res.response.data, res.response.status, token);
          return resolve(res);
        });
    });
  }

  sessionPing(hearbeat: number, token: string): Promise<LogoutResponseParams> {
    return new Promise((resolve, reject) => {
      this.transport
        .get(Links.ping, {
            headers: {
              'Heartbeat-Count': hearbeat,
              'Authorization': `Basic ${token}`,
            }
          })
        .then(res => resolve(res.data))
        .catch(res => {
          ErrorLogger.logErrorRequest(Links.ping, {}, res.response.data, res.response.status, token);
          return resolve(res);
        });
    });
  }

  storeSessionDataInSessionStorage(data: {authorizationToken?: string, user?: string, role?: string}) {
    StorageSession.set('authorizationToken', data.authorizationToken, 'HuM_');
    StorageSession.set('sessionUser', data.user, 'HuM_');
    StorageSession.set('sessionRole', data.role, 'HuM_');
  }
}
