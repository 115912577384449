import { RootState } from '../store/types.d'
import { createSelector } from 'reselect';
import { Data } from '../reducers/data';
import { FORM_VIEWING_YEAR, valuesToDisplay, FORM_HISTORY_USER, valuesToDisplayHistory, valuesToDisplayMaterials, FORM_MATERIAL, valuesToDisplayLogs, FORM_SETTINGS, valuesToDisplayReports } from '../data/constants';
import {
    createOption,
    createOptionWithName,
    createOptionWithNameAndEmptyValue,
    createOptionWithNameAndEmptyValueWithoutCode,
    createOptionWithoutNameAndEmptyValue,
} from '../helpers/helper';
import * as prop from 'ramda/src/prop';

export const getSelectedDashboardYear = (state: RootState): string => {
    const values = prop('values', state.form[FORM_VIEWING_YEAR]);
    return values && values.selected_year;
}

export const getSelectedHistoryUser = (state: RootState) => {
    const values = prop('values', state.form[FORM_HISTORY_USER]);
    return values && values.history_user;
}

export const getMaterialFormData = (state: RootState) => prop('values', state.form[FORM_MATERIAL])

export const getOrderDetailsTrigger = (state: RootState) =>
    state.data.positionOrderDetailsTrigger;

export const getPurchaseRawData = (state: RootState): Data =>
    state.data.purchase;

export const getPurchaseOrdersRawData = (state: RootState): Data =>
    state.data.purchase_orders;

export const getPurchaseSearchData = (state: RootState) =>
    state.search.searchedPurchases && mapRawData(state.search.searchedPurchases, true);

export const getSalesRawData = (state: RootState): Data =>
    state.data.sales;

export const getSalesOrdersRawData = (state: RootState): Data =>
    state.data.sales_orders;

export const getSaleSearchData = (state: RootState) =>
    state.search.searchedSales && mapRawData(state.search.searchedSales, true);

export const getCustomersRawData = (state: RootState): Data =>
    state.data.customers;

export const getDataIsLoaded = (state: RootState): { [index: string]: boolean } =>
    state.data.isLoaded;

export const getMetaDataIsLoaded = (state: RootState): boolean =>
    state.data.isLoaded_meta;

export const getSearchedValue = (state: RootState, type: string) =>
    state.search.search && state.search.search[type];

export const getSearchedCustomers = (state: RootState): Data =>
    state.search.searchedInOrder;

export const getCustomersSearchData = (state: RootState) =>
    state.search.searchedCustomers && mapCustomerData(state.search.searchedCustomers);

export const getCostCenters = (state: RootState): Data =>
    state.data.cost_centers;

export const getMaterials = (state: RootState): Data =>
    state.data.materials;

export const getCurrencies = (state: RootState): Data =>
    state.data.currencies;

export const getAdditionalCosts = (state: RootState): Data =>
    state.data.additional_costs;

export const getCountries = (state: RootState): Data =>
    state.data.countries;

export const getRepresentatives = (state: RootState): Data =>
    state.data.representatives;

export const getFeaturesAvailability = (state: RootState): Data =>
    state.data.features_availability;

export const getLogs = (state: RootState): Data =>
    state.data.log;

export const getReports = (state: RootState): Data =>
    state.data.reports;

export const getSettingsData = (state: RootState) =>
    state.data.settings;

export const getRawHistory = (state: RootState): Data =>
    state.data.history && (state.data.history.history as Data);

export const getOrderEdited = (state: RootState): boolean =>
    state.data.orderIsEdited;

export const getSettingsFormValuesData = (state: RootState) =>
    prop('values', state.form[FORM_SETTINGS]);

const getData = (data: Data, valueToDisplay: string[]) => {
    if (!data) {
        return data;
    }

    const values: { [index: string]: Data } = {};

    Object.keys(data).map(key => {
        const id = String(data[key].id);
        values[id] = {};
        valueToDisplay.map((p: string) => {
            values[id][p] = { [p]: data[key][p] };
        })
    });
    return values;
}

export const getAdditionalCostsFeatureAvailability = createSelector(
    [getFeaturesAvailability],
    (featuresAvailability) => Boolean(featuresAvailability.additional_costs)
);

export const getDeliveryNoteFeatureAvailability = createSelector(
    [getFeaturesAvailability],
    (featuresAvailability) => Boolean(featuresAvailability.delivery_note)
);

export const getLogsData = createSelector(
    [getLogs],
    (data) => getData(data, valuesToDisplayLogs)
)

export const getReportsData = createSelector(
    [getReports],
    (data) => getData(data, valuesToDisplayReports)
)

export const getMaterialsData = createSelector(
    [getMaterials, getCurrencies],
    (data, currenciesData) => {
        const getAllCombinations = (arrays: any[]) => {
            const result: any[] = [];

            const helper = (current: any[], index: any) => {
                if (index === arrays.length) {
                    result.push(current.slice());
                    return;
                }

                for (let i = 0; i < arrays[index].length; i++) {
                    current[index] = arrays[index][i];
                    helper(current, index + 1);
                }
            };

            helper([], 0);

            return result;
        };

        Object.keys(data).forEach(materialKey => {
            data[materialKey].multiplier = Object.values(data[materialKey].price_units)
                .map(price_unit => price_unit.multiplier).join(', ');

            const price_weight_units = Object.values(data[materialKey].price_units)
                .map(price_unit => price_unit.price_weight_unit);

            const currencies = Object.values(currenciesData).map(currency => currency.currency_sign);

            const combinations = getAllCombinations([currencies, price_weight_units]);

            data[materialKey].price_unit = combinations.map(combination => `${combination[0]}/${combination[1]}`).join(', ');

            data[materialKey].weight_unit = (data[materialKey].weight_units as string[]).join(', ');
        });

        return getData(data, valuesToDisplayMaterials)
    }
)

export const getHistory = createSelector(
    [getRawHistory],
    (data) => getData(data, valuesToDisplayHistory)
)

export const getDivisions = (state: RootState): Data =>
    state.data.divisions;

export const getWarehouses = (state: RootState): Data =>
    state.data.warehouses;

export const getShippingTerms = (state: RootState): Data =>
    state.data.shipping_terms;

export const getTermsOfPayment = (state: RootState): Data =>
    state.data.terms_of_payment;

export const getDashboard = (state: RootState) =>
    state.data.dashboard;

export const getDashboardYears = (state: RootState) =>
    state.data.dashboard_years;

export const getRequestStatus = (state: RootState): string =>
    state.data.requestStatus;

export const getHistoryUsers = (state: RootState): Data =>
    state.data.history && (state.data.history.users as Data);

export const getPrevMonthLocked = (state: RootState): boolean =>
    Boolean(state.data.prev_month_locked)

export const getHistoryUsersOptions = createSelector(
    [getHistoryUsers],
    (users) => users && Object.keys(users).map(key => (createOption(String(users[key].login), String(users[key].login))))
)

export const mapRawData = (rawValues: Data, showCanceled?: boolean) => {
    if (!rawValues) {
        return rawValues;
    }
    const values: { [index: string]: Data } = {};

    Object.keys(rawValues).map(key => {
        if (!rawValues[key].canceled || showCanceled) {
            const id = String(rawValues[key].id);
            values[id] = {};
            valuesToDisplay.map((p: string) => {
                values[id][p] = { [p]: rawValues[key][p] };
            })
        }
    });
    return values;
};

export const mapCustomerData = (rawValues: Data) => {
    if (!rawValues) {
        return rawValues;
    }
    const values: { [index: string]: Data } = {};

    Object.keys(rawValues).map(key => {
        const id = String(rawValues[key].sap_id);
        values[id] = {};
        Object.keys(rawValues[key]).map((p: string) => values[id][p] = { [p]: rawValues[key][p] });
    });
    return values;
};

const numericFields = ["weight", "position_price", "unit_price", "unmatched_weight", "price"];

export const prepareData = (rawValues: Data, status?: string): Data => {
    if (!rawValues) {
        return rawValues;
    }
    const values: Data = {};
    Object.keys(rawValues).map(key => {
        const id = String(rawValues[key].id || rawValues[key].sap_id);
        values[id] = {};
        Object.keys(rawValues[key]).map((p: string) => {
            if (p === 'order_date') {
                values[id][p] = String(rawValues[key][p]).replace('T', ' ');
            } else {
                if (numericFields.includes(p)) {
                    values[id][p] = parseFloat(String(Math.round(Number(rawValues[key][p]) * 1000) / 1000)).toFixed(3);
                } else {
                    values[id][p] = rawValues[key][p];
                }
            }
        });
        if (status) {
            values[id].status = status;
        }
    });
    return values;
};


export const prepareSettingsData = (rawValues: Data): Data => {
    if (!rawValues) {
        return rawValues;
    }
    const values: Data = {};
    Object.keys(rawValues).map(key => {
        const id = String(rawValues[key].key);
        values[id] = {};
        Object.keys(rawValues[key]).map((p: string) => {
            values[id][p] = rawValues[key][p];
        });
    });
    return values;
};

export const getOptions = createSelector(
    [getCostCenters, getMaterials, getDivisions, getWarehouses, getShippingTerms, getTermsOfPayment, getAdditionalCosts, getCountries, getRepresentatives, getCurrencies],
    (cost_centers, materials, divisions, warehouses, shipping_terms, terms_of_payment, additional_costs, countries, representatives, currencies): { [index: string]: Array<{ value: string, text: string }> } => ({
        cost_centers: cost_centers && Object.keys(cost_centers).map(key =>
            createOption(String(cost_centers[key].cost_center_code), String(cost_centers[key].cost_center_code))
        ),
        materials: materials && Object.keys(materials).map(key => ({
            ...createOption(String(materials[key].material_code), `${materials[key].name} (${materials[key].material_code})`)
        })),
        divisions: divisions && Object.keys(divisions).map(key =>
            createOption(String(divisions[key].code), String(divisions[key].code))
        ),
        warehouses: createOptionWithNameAndEmptyValue(warehouses),
        shipping_terms: createOptionWithNameAndEmptyValue(shipping_terms),
        terms_of_payment: createOptionWithNameAndEmptyValue(terms_of_payment),
        additional_costs: createOptionWithName(additional_costs),
        countries: createOptionWithNameAndEmptyValueWithoutCode(countries),
        representatives: createOptionWithoutNameAndEmptyValue(representatives),
        currencies: currencies && Object.values(currencies).map(currency =>
            createOption(String(currency.currency_code), String(currency.currency_code))
        )
    })
);

export const getMaterialsColor = createSelector(
    [getMaterials],
    (materials) => {
        const materialColors: { [index: string]: string } = {};

        materials && Object.keys(materials).map(key => {
            materialColors[String(materials[key].material_code)] = String(materials[key].color);
        })

        return materialColors;
    })

export const getPurchaseData = createSelector(
    [getPurchaseRawData],
    (data) => mapRawData(data, true)
)

export const getSalesData = createSelector(
    [getSalesRawData],
    (data) => mapRawData(data, true)
)

export const getCustomersData = createSelector(
    [getCustomersRawData],
    (data) => mapCustomerData(data)
)

export const getHighlightTimeout = (): number => 2000;
export const getAlertTimeout = (): number => 5000;

export const parseDashboard = (data: any) => {
    const orders: { [index: string]: { [index: string]: Data } } = {
        purchase: {},
        sales: {},
        inventory: {},
        difference: {},
        profits_and_losses: {},
    };
    const years: { [index: string]: string } = {};
    Object.keys(data).map(id => {
        if (id !== "years" && id !== "selected_year") {
            const year = data[id].year;
            const material = data[id].material_code;
            if (!Object.keys(years).includes(year)) {
                Object.keys(orders).map(k => {
                    if (!orders[k][year]) {
                        orders[k][year] = {};
                    }
                })
                years[year] = year;
            }
            orders.purchase[year][id] = { material, weight: data[id].purchases_weight, price: data[id].purchases_price };
            orders.sales[year][id] = { material, weight: data[id].sales_weight, price: data[id].sales_price };
            orders.difference[year][id] = { material, weight: data[id].orders_diff_weight, price: data[id].orders_diff_price };
            orders.profits_and_losses[year][id] = { material, weight: data[id].pl_weight, price: data[id].pl_price };
            orders.inventory[year][id] = { material, weight: data[id].inventory_weight, price: data[id].inventory_price };
        }
    })
    if (data.years) {
        Object.keys(data.years).map(key => years[String(data.years[key].year)] = (String(data.years[key].year)));
    }
    return { orders, years };
}
