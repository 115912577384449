import { Data } from "../reducers/data";
import { orderFieldNames, shippingAddressFieldNames } from '../reducers/form';


export const customSortByFields = (a: any, b: any, field: string): number => {
    switch (field) {
        case 'locked':
        case 'internal':
            return (Number(!!a) - Number(!!b));
        case 'material_code':
        case 'notes':
        case 'order_no':
        case 'order_type':
        case 'billing_attachment_text':
        case 'terms_of_payment':
        case 'vat_transaction_indicator':
        case 'value_date':
        case 'name':
        case 'address':
        case 'weight_unit':
        case 'price_unit':
        case 'color':
        case 'created_by':
        case 'action':
        case 'login':
        case 'call_type':
        case 'function':
        case 'severity':
            return ('' + a).localeCompare(b) * (-1);
        case 'order_date':
        case 'created_at':
            return ('' + a).localeCompare(b);
        default:
            return (Number(a) - Number(b));
    }
}

export const generateId = (): string => {
    return '_' + Math.random().toString(36).slice(2, 11);
};

export const getDate = () => {
    const date = new Date();
    return `${date.getFullYear()}_${date.getMonth() + 1}_${date.getDate()}`;
}

export const convertCredentialsToBase64 = (loginData: { [fieldName: string]: any }): string => {
    return Buffer.from(loginData.login + ':' + loginData.password).toString('base64');
};

export const createOption = (value: string, text: string) => ({ value, text });

export const createOptionWithName = (option: Data) =>
    [...(option ? Object.keys(option).map(key =>
        createOption(String(option[key].code), `${option[key].code} - ${option[key].name}`)
    ) : [])];

export const createOptionWithNameAndEmptyValueWithoutCode = (option: Data) =>
    [{ text: "", value: "" }, ...(option ? Object.keys(option).map(key =>
        createOption(String(option[key].code), `${option[key].name}`)
    ) : [])];

export const createOptionWithNameAndEmptyValue = (option: Data) =>
    [{ text: "", value: "" }, ...(option ? Object.keys(option).map(key =>
        createOption(String(option[key].code), `${option[key].code} - ${option[key].name}`)
    ) : [])];

export const createOptionWithoutNameAndEmptyValue = (option: Data) =>
    [{ text: "", value: "" }, ...(option ? Object.keys(option).map(key =>
        createOption(String(option[key].code), `${option[key].code}`)
    ) : [])];

export const createOptionForShippingPartners = (option: { [index: string]: string | number | boolean | JSX.Element }[]) =>
    [{ text: "Select the partner or input custom address...", value: "" }, ...(option ? option.map((partner, index) =>
        createOption(String(index), `${partner.shipping_address_primary_name}${partner.blocked ? ' - BLOCKED' : ''}`)
    ) : [])];

export const createOptionForDetailsShippingPartners = (option: { [index: string]: string | number | boolean | JSX.Element }[]) =>
    [{ text: "Current address configuration", value: "" }, ...(option ? option.map((partner, index) =>
        createOption(String(index), `${partner.shipping_address_primary_name}${partner.blocked ? ' - BLOCKED' : ''}`)
    ) : [])];

export const createOptionForAdditionalSaleCustomers = (option: { [index: string]: string | number | boolean | JSX.Element }[]) =>
    [{ text: "Select the partner or use the search...", value: "" }, ...(option ? option.map((partner, index) =>
        createOption(String(partner.sap_id), `${partner.sap_id} ${partner.primary_name ? partner.primary_name : partner.shipping_address_primary_name}${partner.blocked ? ' - BLOCKED' : ''}`)
    ) : [])];

export const getInactiveOrderFormFields = (
    readonly: boolean,
    isInvoiceCreated: boolean,
    sapRelevant: boolean,
    isLimitedUpdate: boolean,
    prevMonthLock: boolean,
    isRegisteredInSap: boolean
): string[] => {
    const inactiveSubGroups: string[][] = [];

    if (readonly) {
        inactiveSubGroups.push(Object.values(orderFieldNames).concat(Object.values(shippingAddressFieldNames)));
    }

    if (prevMonthLock) {
        const { internal_notes, invoice_id, ...result } = orderFieldNames;
        inactiveSubGroups.push(Object.values(result).concat(Object.values(shippingAddressFieldNames)));
    }

    if (isRegisteredInSap) {
        const { internal_notes, ...result } = orderFieldNames;
        inactiveSubGroups.push(Object.values(result).concat(Object.values(shippingAddressFieldNames)));
    }

    if (isInvoiceCreated) {
        const { internal_notes, ...result } = orderFieldNames;
        inactiveSubGroups.push(Object.values(result).concat(Object.values(shippingAddressFieldNames)));
    }

    if (isLimitedUpdate) {
        const { internal_notes, relevant_for_sap, ...result } = orderFieldNames;
        inactiveSubGroups.push(Object.values(result).concat(Object.values(shippingAddressFieldNames)));
    }

    if (sapRelevant) {
        inactiveSubGroups.push([orderFieldNames.invoice_id, orderFieldNames.delivery_note_id]);
    }

    const inactiveFields = new Set([].concat(...inactiveSubGroups));

    return Array.from(inactiveFields);
}

export const getYearFromDate = (date: string) => new Date(date).getFullYear();

export const sameYear = (firstYear: number, secondYear: number) => firstYear === secondYear;
