export const Links: { [index: string]: string } = {
    login: '/api/azure/login',
    logout: '/api/azure/logout',
    ping: '/api/auth/ping',
    purchases: '/api/purchases/get-positions',
    sales: '/api/sales/get-positions',
    purchase_orders: '/api/purchases/get-orders',
    sale_orders: '/api/sales/get-orders',
    customers: '/api/customers/search',
    metadata: '/api/settings/get-metadata',
    create_purchase: '/api/purchases/create',
    create_sales: '/api/sales/create',
    dashboard: '/api/dashboard/get-data',
    getCustomerRepresentative: '/api/customers/get-customer-representative',

    details_purchase: '/api/trading/get-purchase-order-by-position',
    cancel_purchase: '/api/trading/cancel-purchase-order',
    cancel_purchase_delivery: '/api/trading/cancel-purchase-delivery',
    cancel_purchase_invoice: '/api/trading/cancel-purchase-invoice',
    details_sales: '/api/trading/get-sale-order-by-position',
    cancel_sales: '/api/trading/cancel-sale-order',
    cancel_sales_delivery: '/api/trading/cancel-sale-delivery',
    cancel_sales_invoice: '/api/trading/cancel-sale-invoice',
    change_sales: '/api/sales/update',
    change_purchase: '/api/purchases/update',

    tradingPurchaseLock: '/api/trading/purchase-lock',
    tradingPurchaseUnlock: '/api/trading/purchase-unlock',
    tradingPurchaseCancel: '/api/trading/purchase-cancel',
    tradingSaleLock: '/api/trading/sale-lock',
    tradingSaleUnLock: '/api/trading/sale-unlock',
    tradingSaleCancel: '/api/trading/sale-cancel',
    tradingInventory: '/api/trading/transfer-to-inventory',
    tradingPnL: '/api/trading/transfer-to-profits-and-losses',
    tradingMatch: '/api/trading/match',
    tradingUnlockAll: '/api/trading/batch-unlock',
    tradingLockAll: '/api/trading/batch-lock',

    exportSales: '/api/export/sales',
    exportPurchases: '/api/export/purchases',
    exportMatches: '/api/export/matches',
    exportInventory: '/api/export/inventory',
    exportPnL: '/api/export/profits-and-losses',
    exportMixed: '/api/export/mixed',
    exportMixedWithMatch: '/api/export/mixed_with_match',
    exportMixedNeedsMatch: '/api/export/mixed_needs_match',

    downloadReport: '/api/pdf-files/',
    getReports: '/api/reports/get-reports',

    sapCreateOrder: '/api/sap/register-orders-with-sap',
    sapBillingOrder: '/api/sap/bill-orders-with-sap',
    sapDeliveringOrder: '/api/sap/deliver-orders-with-sap',
    getSapOrders: '/api/sap/orders-to-send-to-sap',
    getBillingSapOrders: '/api/sap/orders-to-bill-with-sap',
    getDeliveringSapOrders: '/api/sap/orders-to-deliver-with-sap',

    history: '/api/history/search',
    logError: '/api/logs/report-error',
    logs: '/api/logs/search',

    addMaterial: '/api/settings/material-create',
    editMaterial: '/api/settings/material-update',
    settingsUpdate: '/api/settings/settings-update',
}
