import { Epic, combineEpics } from 'redux-observable';
import {
    filter,
    map,
    mergeMap
} from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { isOfType } from 'typesafe-actions';
import * as TradingAction from '../actions/trading';
import { RootAction } from '../store/types';
import {
    TRADING_PURCHASE_SELECT,
    TRADING_PURCHASE_LOCK,
    TRADING_MODE_CHANGED,
    TRADING_SALE_SELECT,
    TRADING_PURCHASE_UNLOCK,
    TRADING_SALE_LOCK,
    TRADING_SALE_UNLOCK,
    TRADING_MATCH_CONFIRM,
    TRADING_UNLOCK_ALL,
    FORM_TRADING_MODE,
    SAP_SELECT_ALL_PURCHASE,
    SAP_SELECT_ALL_SALE,
    SAP_SELECT_ALL_ACTIVE,
    DATA_ORDER_EDIT_UNLOCK,
    TRADING_SALE_CANCEL,
    TRADING_PURCHASE_CANCEL,
} from '../data/constants';
import {
    getSelectedPurchase,
    getSelectedSales,
    getMode,
    isCompSalesAllowToSelect,
    isCompPurchaseAllowToSelect,
    getInvoiceablePurchaseItems,
    getInvoiceableSalesItems,
    getActiceSapTable,
    getSelectedSapItems,
    getCredentialsBaseToken
} from '../selectors';
import { TradingServiceClass, TradingResponseParams } from '../services/trading';
import { setErrorMessage } from '../actions/app';
import { APP_GET_INIT, Modes, TRADING_RESET_MODE } from '../data/constants';
import { FormBuilder } from '../components/utils/form/form';
import { FormAction, actionTypes as FormActions } from 'redux-form';
import { SapTables } from '../reducers/trading';
import { lockedSaleByAnotherUser, lockedPurchaseByAnotherUser, isLockedPurchasePosition, isLockedSalePosition, allowMatch } from '../selectors/trading';

const TradingService = new TradingServiceClass();

type Responce = Array<TradingResponseParams>;

const checkResponce = (response: Responce): RootAction[] => {
    const res = response.pop();
    if (res.status !== "OK") {
        return [setErrorMessage(res.message)];
    }
    return [];
}

const handlePurchaseSelect: Epic<RootAction> = (
    action$,
    store
) =>
    action$.pipe(
        filter(action => (isOfType(TRADING_PURCHASE_SELECT, action))
        ),
        map(action => {
            const id = action.payload.id
            const selected = getSelectedPurchase(store.value);
            if (selected && selected.includes(id)) {
                return TradingAction.unlockPurchase(id);
            }
            return TradingAction.lockPurchase(id);
        })
    );

const handleSaleSelect: Epic<RootAction> = (
    action$,
    store
) =>
    action$.pipe(
        filter(action => (isOfType(TRADING_SALE_SELECT, action))
        ),
        map(action => {
            const id = action.payload.id
            const selected = getSelectedSales(store.value);
            if (selected && selected.includes(id)) {
                return TradingAction.unlockSale(id);
            }
            return TradingAction.lockSale(id);
        })
    );

const handleModeChange: Epic<RootAction> = (
    action$,
    store
) =>
    action$.pipe(
        filter(action => (
            (action.type === FormActions.CHANGE &&
                action.meta &&
                action.meta.form === FORM_TRADING_MODE) ||
            (isOfType(TRADING_MODE_CHANGED, action))) &&
            (getSelectedPurchase(store.value).length !== 0 ||
                getSelectedSales(store.value).length !== 0)
        ),
        map(action => {
            return TradingAction.unlockAll();
        })
    );

const handleUnlockAll: Epic<RootAction> = (
    action$,
    store
) =>
    action$.pipe(
        filter(action => (isOfType(TRADING_UNLOCK_ALL, action))
        ),
        mergeMap(action => {
            return []; // Disable locking

            const baseToken = getCredentialsBaseToken(store.value);
            const sales = getSelectedSales(store.value);
            const purchase = getSelectedPurchase(store.value);
            if ((!sales || sales.length === 0) && (!purchase || purchase.length === 0)) {
                return [];
            }
            const queue = TradingService.unlockAll({
                purchase_positions: purchase || [],
                sale_positions: sales || [],
            }, baseToken);
            return forkJoin(queue).pipe(
                mergeMap((response: Responce) => [...checkResponce(response), TradingAction.resetMatch()])
            );
        })
    );

const handlePurchaseLock: Epic<RootAction> = (
    action$,
    store
) =>
    action$.pipe(
        filter(action => (isOfType(TRADING_PURCHASE_LOCK, action))
        ),
        mergeMap(action => {
            const id = action.payload.id;
            if (!isCompPurchaseAllowToSelect(store.value, id)) {
                return [
                    TradingAction.cancelLockPurchase(id),
                    setErrorMessage("Unmatched Weight is not comperable")
                ];
            }
            return []; // Disable locking
            if (isLockedPurchasePosition(store.value, id)) {
                if (lockedPurchaseByAnotherUser(store.value, id)) {
                    return [
                        TradingAction.cancelLockPurchase(id),
                        setErrorMessage("Record locked by another user")
                    ];
                }
                return [];
            }

            const baseToken = getCredentialsBaseToken(store.value);

            const queue = TradingService.lockPurchase(action.payload, baseToken);
            return forkJoin(queue).pipe(
                mergeMap((response: Responce) => {
                    const res = response.pop();
                    if (res.status !== "OK") {
                        return [
                            TradingAction.cancelLockPurchase(id),
                            setErrorMessage(res.message)
                        ];
                    }
                    return [];
                })
            );
        })
    );

const handleSaleLock: Epic<RootAction> = (
    action$,
    store
) =>
    action$.pipe(
        filter(action => (isOfType(TRADING_SALE_LOCK, action))
        ),
        mergeMap(action => {
            const id = action.payload.id;
            if (!isCompSalesAllowToSelect(store.value, id)) {
                return [
                    TradingAction.cancelLockSale(id),
                    setErrorMessage("Unmatched Weight is not comperable")
                ];
            }
            return []; // Disable locking

            if (isLockedSalePosition(store.value, id)) {
                if (lockedSaleByAnotherUser(store.value, id)) {
                    return [
                        TradingAction.cancelLockSale(id),
                        setErrorMessage("Record locked by another user")
                    ];
                } 
                return [];
            }

            const baseToken = getCredentialsBaseToken(store.value);

            const queue = TradingService.lockSale(action.payload, baseToken);
            return forkJoin(queue).pipe(
                mergeMap((response: Responce) => {
                    const res = response.pop();
                    if (res.status !== "OK") {
                        return [
                            TradingAction.cancelLockSale(id),
                            setErrorMessage(res.message)
                        ];
                    }
                    return [];
                })
            );
        })
    );

const handlePurchasUnLock: Epic<RootAction> = (
    action$,
    store
) =>
    action$.pipe(
        filter(action => (isOfType(TRADING_PURCHASE_UNLOCK, action))
        ),
        mergeMap(action => {
            return []; // Disable locking
            const baseToken = getCredentialsBaseToken(store.value);
            const queue = TradingService.unlockPurchase(action.payload, baseToken);
            return forkJoin(queue).pipe(
                mergeMap((response: Responce) => checkResponce(response))
            );
        })
    );

const handleSaleUnLock: Epic<RootAction> = (
    action$,
    store
) =>
    action$.pipe(
        filter(action => (isOfType(TRADING_SALE_UNLOCK, action))
        ),
        mergeMap(action => {
            return []; // Disable locking
            const baseToken = getCredentialsBaseToken(store.value);
            const queue = TradingService.unlockSale(action.payload, baseToken);
            return forkJoin(queue).pipe(
                mergeMap((response: Responce) => checkResponce(response))
            );
        })
    );

const handlePurchasePositionCancel: Epic<RootAction> = (
    action$,
    store
) =>
    action$.pipe(
        filter(action => (isOfType(TRADING_PURCHASE_CANCEL, action))
        ),
        mergeMap(action => {
            const baseToken = getCredentialsBaseToken(store.value);
            const queue = TradingService.cancelPurchase(action.payload, baseToken);
            return forkJoin(queue).pipe(
                mergeMap((response: Responce) => checkResponce(response))
            );
        })
    );

const handleSalePositionCancel: Epic<RootAction> = (
    action$,
    store
) =>
    action$.pipe(
        filter(action => (isOfType(TRADING_SALE_CANCEL, action))
        ),
        mergeMap(action => {
            const baseToken = getCredentialsBaseToken(store.value);
            const queue = TradingService.cancelSale(action.payload, baseToken);
            return forkJoin(queue).pipe(
                mergeMap((response: Responce) => checkResponce(response))
            );
        })
    );

const handleConfirmMutch: Epic<RootAction> = (
    action$,
    store
) =>
    action$.pipe(
        filter(action => (isOfType(TRADING_MATCH_CONFIRM, action))
        ),
        mergeMap(action => {
            const mode = getMode(store.value);
            const purchase_positions: { [index: string]: number } = {};
            const sale_positions: { [index: string]: number } = {};

            const sales = getSelectedSales(store.value);
            const purchase = getSelectedPurchase(store.value);

            sales && sales.map((values: string, index: number) => sale_positions[values] = index);
            purchase && purchase.map((values: string, index: number) => purchase_positions[values] = index);

            const params = {
                purchase_positions,
                sale_positions
            };
            const baseToken = getCredentialsBaseToken(store.value);
            let queue: Promise<TradingResponseParams>;
            switch (mode) {
                case "BUY_SELL":
                    if(!allowMatch(store.value)) {
                      return [setErrorMessage("Match is not allowed")];
                    }
                    queue = TradingService.tradingMatch(params, baseToken);
                    break;
                case "INVENORY":
                    queue = TradingService.tradingInventory(params, baseToken);
                    break;
                case "PROFITS_LOSSES":
                    queue = TradingService.tradingPnL(params, baseToken);
                    break;
                default:
                    return [];
            }
            return forkJoin(queue).pipe(
                mergeMap((response: Responce) => [...checkResponce(response), TradingAction.resetMatch(), TradingAction.reestMode()])
            );
        })
    );

const formCotnrolls = FormBuilder.formActions(FORM_TRADING_MODE);

const handleModeSet: Epic<FormAction> = (
    action$,
    store
) =>
    action$.pipe(
        filter(action => (isOfType(APP_GET_INIT, action) || isOfType(TRADING_RESET_MODE, action))
        ),
        map(action =>
            formCotnrolls.setValue("mode_select", Modes.buy_sell))
    );

const handlePositionUnLock: Epic<RootAction> = (
    action$,
    store
) =>
    action$.pipe(
        filter(action => (isOfType(DATA_ORDER_EDIT_UNLOCK, action))
        ),
        mergeMap(action => {
            const baseToken = getCredentialsBaseToken(store.value);
            const queue = TradingService.unlockAll(action.payload, baseToken);
            return forkJoin(queue).pipe(
                mergeMap((response: Responce) => checkResponce(response))
            );
        })
    );

const handleSapPurchaseSelectAll: Epic<RootAction> = (
    action$,
    store
) =>
    action$.pipe(
        filter(action => (isOfType(SAP_SELECT_ALL_PURCHASE, action))
        ),
        map(action => {
            const items = getInvoiceablePurchaseItems(store.value);
            return TradingAction.setSapItems(items);
        })
    );

const handleSapSaleSelectAll: Epic<RootAction> = (
    action$,
    store
) =>
    action$.pipe(
        filter(action => (isOfType(SAP_SELECT_ALL_SALE, action))
        ),
        map(action => {
            const items = getInvoiceableSalesItems(store.value);
            return TradingAction.setSapItems(items);
        })
    );
const handleSapSelectAllActive: Epic<RootAction> = (
    action$,
    store
) =>
    action$.pipe(
        filter(action => (isOfType(SAP_SELECT_ALL_ACTIVE, action))
        ),
        map(action => {
            const active = getActiceSapTable(store.value);
            switch (active) {
                case SapTables.sale:
                    return TradingAction.setSapItems(getInvoiceableSalesItems(store.value));
                case SapTables.purchase:
                default:
                    return TradingAction.setSapItems(getInvoiceablePurchaseItems(store.value));
            }
        })
    );


export default combineEpics(
    handleModeChange,
    handlePurchaseSelect,
    handlePurchaseLock,
    handlePurchasUnLock,
    handleSaleSelect,
    handleSaleLock,
    handleSaleUnLock,
    handleConfirmMutch,
    handleUnlockAll,
    handleModeSet,
    handlePositionUnLock,
    handlePurchasePositionCancel,
    handleSalePositionCancel,
    handleSapPurchaseSelectAll,
    handleSapSaleSelectAll,
    handleSapSelectAllActive
);
