import { FormAction } from 'redux-form';
import { Epic, combineEpics } from 'redux-observable';
import {
    filter,
    map,
    mergeMap
} from 'rxjs/operators';
import { isOfType } from 'typesafe-actions';
import * as SocketAction from '../actions/socket';
import * as DataAction from '../actions/data';
import { RootAction, RootState } from '../store/types';
import { SOCKET_DATA_UPDATE, USER_LOGED_IN_SUCCESS, Statuses, USER_LOGED_OUT } from '../data/constants';
import { getApplicationInit, prepareData, parseDashboard, getActiveOrder, prepareSettingsData, getCredentialsBaseToken, getSelectedDashboardYear, getUserPermissions, getSessionUser } from '../selectors/index';
import { setSuccessMessage, setInfoMessage } from '../actions/app';
import { getOrderEdited } from '../selectors/data';
import { orderInfo, saleSuccess, dashboardInfo, settingsInfo, materialseSuccess, purchaseSuccess } from '../reducers/app';
import { getYearFromDate, sameYear } from '../helpers/helper';

const handleConnectSocketOnInit: Epic<FormAction, RootAction> = (
    action$,
    store
) =>
    action$.pipe(
        filter(action =>
            (isOfType(USER_LOGED_IN_SUCCESS, action) &&
                !getApplicationInit(store.value))
        ),
        map((action: FormAction) => {
            return SocketAction.connectSocket({ login: getSessionUser(store.value) });
        })
    );

const handleDisconnectSocket: Epic<FormAction, RootAction> = (
    action$,
    store
) =>
    action$.pipe(
        filter(action =>
            (isOfType(USER_LOGED_OUT, action) &&
                !getApplicationInit(store.value))
        ),
        map((action: FormAction) => {
            return SocketAction.disconnectSocket();
        })
    );


const getDataActionByType = (type: string, data: any, state: RootState) => {
    switch (type) {
        case "dashboard":
        case "dashboard_data_changed": {
            const dashboardData = parseDashboard(data);
            const userPermissions = getUserPermissions(state);

            return userPermissions.dashboard ? [
                DataAction.updateDashboard(dashboardData),
                setInfoMessage(dashboardInfo),
            ] : [];
        }
        case "material_changed":{
            const materialData = prepareData(data, Statuses.new);
            // const materialDataLength = Object.keys(materialData).length;
            return [
                DataAction.updateMaterials(materialData),
                DataAction.updateMaterialsStatus(data),
                setSuccessMessage(materialseSuccess),
            ];
        }
        case "settings_changed":{
            const settingsData = prepareSettingsData(data);
            return [
                DataAction.updateSettings(settingsData),
                setInfoMessage(settingsInfo),
            ];
        }
        case "purchase_data_changed": {
            const preparedData = prepareData(data, Statuses.new);
            const activeOrder = getActiveOrder(state);
            const userPermissions = getUserPermissions(state);
            let updateOrderid: string;
            let updatePurchase = false;

            Object.keys(preparedData).map(item => {
                if (activeOrder && activeOrder.type === 'purchase' && preparedData[item].order_id === activeOrder.id) {
                    updateOrderid = String(preparedData[item].id);
                }

                if (!userPermissions.dashboard || sameYear(
                    getYearFromDate(preparedData[item].order_date as string),
                    Number(getSelectedDashboardYear(state))
                )) {
                    updatePurchase = true;
                }
            });

            return [
                ...(updatePurchase ? [DataAction.updatePurchase(preparedData), DataAction.updatePurchaseStatus(data)] : []),
                setSuccessMessage(purchaseSuccess),
                ...(updateOrderid && !getOrderEdited(state) ? [
                    DataAction.getOrderPurchaseDetails(updateOrderid),
                    setInfoMessage(orderInfo),
                ] : [])
            ];
        }
        case "sale_data_changed":{
            const preparedData = prepareData(data, Statuses.new);
            const activeOrder = getActiveOrder(state);
            const userPermissions = getUserPermissions(state);
            let updateOrderid: string;
            let updateSales = false;

            Object.keys(preparedData).map(item => {
                if (activeOrder && activeOrder.type === 'sale' && preparedData[item].order_id === activeOrder.id) {
                    updateOrderid = String(preparedData[item].id);
                }

                if (!userPermissions.dashboard || sameYear(
                    getYearFromDate(preparedData[item].order_date as string),
                    Number(getSelectedDashboardYear(state))
                )) {
                    updateSales = true;
                }
            });

            return [
                ...(updateSales ? [DataAction.updateSales(preparedData), DataAction.updateSalesStatus(data)] : []),
                setSuccessMessage(saleSuccess),
                ...(updateOrderid && !getOrderEdited(state) ? [
                    DataAction.getOrderSalesDetails(updateOrderid),
                    setInfoMessage(orderInfo),
                ] : [])
            ];
        }
        default:
            return [];
    }
}

const handleSocketUpdateData: Epic<FormAction, RootAction> = (
    action$,
    store
) =>
    action$.pipe(
        filter(action => isOfType(SOCKET_DATA_UPDATE, action)),
        mergeMap((action: FormAction) => {
            const data = action.payload.data;
            return getDataActionByType(data.type, data.data, store.value);
        })
    );

export default combineEpics(
    handleConnectSocketOnInit,
    handleSocketUpdateData,
    handleDisconnectSocket
);
