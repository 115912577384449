import { Epic, combineEpics } from 'redux-observable';
import {
    filter,
    map,
    mergeMap,
    debounceTime
} from 'rxjs/operators';
import { isOfType } from 'typesafe-actions';
import * as SearchAction from '../actions/search';
import { RootAction } from '../store/types';
import { forkJoin } from 'rxjs';
import { prepareData, getCredentialsBaseToken, getSelectedDashboardYear } from '../selectors';
import { DataServiceClass, DataResponseParams } from '../services/data';
import { setErrorMessage, } from '../actions/app';
import { Data } from '../reducers/data';
import { SEARCH_START, SEARCH_CLEAR } from '../data/constants';

const DataService = new DataServiceClass();

type Responce = Array<DataResponseParams>;

const checkResponce = (response: Responce, action: (data: Data) => RootAction): RootAction => {
    const res = response.pop();
    if (res.status !== "OK") {
        return setErrorMessage(res.message);
    }
    return action(prepareData(res.data));
}

const handleSearchStart: Epic<RootAction> = (
    action$,
    store
) =>
    action$.pipe(
        filter(action =>
            (isOfType(SEARCH_START, action))
        ),
        debounceTime(1000),
        mergeMap(action => {
            const baseToken = getCredentialsBaseToken(store.value);
            const selectedYear = getSelectedDashboardYear(store.value) || null;
            let queue: Promise<DataResponseParams>;
            let newAction: (data: Data) => RootAction;

            switch (action.payload.type) {
                case "CUSTOMERS":
                    queue = DataService.getCustomers({ needle: action.payload.search }, baseToken);
                    newAction = SearchAction.setSerachedCustomers;
                    break;
                case "SALE":
                    queue = DataService.getSales({ needle: action.payload.search, year: selectedYear }, baseToken);
                    newAction = SearchAction.setSerachedSale;
                    break;
                case "PURCHASE":
                    queue = DataService.getPurchase({ needle: action.payload.search, year: selectedYear }, baseToken);
                    newAction = SearchAction.setSerachedPurchase;
                    break;
                case "IN_ORDER":
                    queue = DataService.getCustomers({ needle: action.payload.search }, baseToken);
                    newAction = SearchAction.setSerachedInOrder;
                    break;
                default:
                    return [];
            }
            return forkJoin(queue).pipe(
                map((response: Responce) => checkResponce(response, newAction))
            );
        })
    );

const handleSearchClear: Epic<RootAction> = (
    action$,
    store
) =>
    action$.pipe(
        filter(action =>
            (isOfType(SEARCH_CLEAR, action))
        ),
        mergeMap(action => {
            let newAction: (data: Data) => RootAction;
            switch (action.payload.type) {
                case "CUSTOMERS":
                    newAction = SearchAction.setSerachedCustomers;
                    break;
                case "SALE":
                    newAction = SearchAction.setSerachedSale;
                    break;
                case "PURCHASE":
                    newAction = SearchAction.setSerachedPurchase;
                    break;
                case "IN_ORDER":
                    newAction = SearchAction.setSerachedInOrder;
                    break;
                default:
                    return [];
            }
            return [newAction(null)]
        })
    );



export default combineEpics(
    handleSearchStart,
    handleSearchClear
);
