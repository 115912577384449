//To change translations simply modify right part of expression
//Example: 'xxx': 'yyy' => 'xxx': 'zzz'

export default {
    'Login': 'Anmeldung',
    'Password': 'Passwort',
    'Username': 'Nutzername',
    'New Purchase': 'Neuer Ankauf',
    'New Sale': 'Neuer Verkauf',
    'You are logged in as': 'Sie sind eingeloggt als',
    'Logout': 'Ausloggen',
    'Customer': 'Kunde',
    'Purchase': 'Ankauf',
    'Sale': 'Verkauf',
    'Compensation': 'Ausgleich',
    'System Activities': 'Systemaktivitäten',
    'Inventory': 'Bestand',
    'Profits and Losses': 'GuV',
    'PnL': 'GuV',
    'Matches': 'Ausgleiche',
    'Difference': 'Differenz',
    'Viewing Year': 'Betrachtungsjahr',
    'Create': 'Speichern',
    'Close without saving': 'Ohne Speichern schließen',
    'Start Search': 'Suche starten',
    'Cost Center': 'Kostenstelle',
    'Division': 'Sparte',
    'Weight': 'Gewicht',
    'Price': 'Preis',
    'Price in €': 'Preis in €',
    'Position Price': 'Positionspreis',
    'Material': 'Material',
    'Write a comment on the customer': 'Kommentar zum Kunden anlegen',
    'Internal Notes': 'Interne Bemerkung',
    'SAP-Comment': 'SAP-Kommentar',
    'Country': 'Land',
    'Customer number / Company name': 'Kundennummer / Firmenname',
    'Selected': 'Ausgewählt',
    'Address': 'Adresse',
    'Blocked': 'Gesperrt',
    'Order no.': 'Bestellnummer',
    'Order Date': 'Auftragsdatum',
    'Order Type': 'Auftragsart',
    'Invoice Date': 'Rechnungsdatum',
    'Terms of payment': 'Zahlungsbedingung',
    'Notes': 'Bemerkung',
    'Incoterm': 'Incoterm',
    'Delivery Note': 'Lieferschein',
    'Shipping Terms': 'Versandbedingungen',
    'VAT-indicator': 'MwSt.-Indikator',
    'Valuta date': 'Valutadatum',
    'Value Date': 'Valutadatum',
    'Address(s)': 'Adressfelder',
    'Street': 'Straße',
    'House Number': 'Hausnummer',
    'Postcode': 'Postleitzahl',
    'City': 'Stadt',
    'Storage Location Code': 'Speicherortcode',
    'Billing Attachment Text': 'Abrechnungsanhangstext',
    'primary_name': 'Name',
    'secondary_name': 'Name_2',
    'street': 'Straße',
    'house_number': 'Hausnummer',
    'post_code': 'Postleitzahl',
    'city': 'Stadt',
    'contact': 'Vertreternr',
    'incoterm': 'Incoterm',
    'incoterm2': 'Incoterm2',
    'Delivery Partner ID': 'Lieferpartner-ID',
    'Weight Account Partner ID' : 'Gewichtskonto-Partner-ID',
    'Please select': 'Bitte auswählen',
    'Customer ID': 'Kunden-ID',
    'positions': 'Positionen',
    'additional_costs': 'Zusätzliche Kosten',

    'Representative(s)': 'Vertreternr',
    'Matching mode': 'Ausgleichs-Modus',
    'Confirm match': 'Ausgleich bestätigen',
    'Buy / Sell': 'Kaufen / Verkaufen',
    'SAP': 'SAP',
    'Export': 'Export',
    'Export Sales': 'Verkauf exportieren',
    'Export Purchases': 'Ankauf exportieren',
    'Export Matches': 'Ausgleich exportieren',
    'Export Inventory': 'Bestand exportieren',
    'Export Profit And Losses': 'GuV exportieren',
    'Export Mixed Orders': 'Ankauf & Verkauf exportieren',
    'Export Mixed Orders With Matched Weight': 'Positionen ohne Zuordnung exportieren',
    'Export Mixed Orders Which Require Matching': 'nicht-0-Liste exportieren',
    'Change Position': 'Position ändern',
    'Remove Position': 'Position entfernen',
    'Unlock Position': 'Position entsperren',
    'Lock Position': 'Position sperren',
    'Do you really want to remove this position?': 'Möchten Sie diese Position wirklich entfernen?',
    'Mixed': 'Gemischt',
    'MixedWithMatch': 'nicht-0-Liste',
    'Cancel': 'Stornieren',
    'Confirm': 'Bestätigen',

    'Order Details': 'Bestelldetails',
    'Purchase Order Details': 'Bestellungsdetails',
    'Sale Order Details': 'Verkaufsauftragsdetails',
    'Order ID': 'Auftragsnummer',
    'Positions': 'Positionen',
    'History': 'Historie',
    'Close': 'Schließen',
    'Delete Order': 'Auftrag löschen',
    'Show/Edit Order Details': 'Auftragsdetails anzeigen / bearbeiten',
    'Order Status': 'Bestellstatus',
    'Active': 'Aktiv',
    'Deleted': 'Gelöscht',
    'Shipping Address': 'Lieferadresse',
    'Add Position': 'Position hinzufügen',
    'Add Additional Cost': 'Zusätzliche Kosten hinzufügen',
    'Add Shipping Address': 'Lieferadresse hinzufügen',
    'The partner is blocked': 'Der Partner ist gesperrt',
    'Save Changes': 'Änderungen speichern',
    'Discard Changes': 'Änderungen verwerfen',
    'Edit': 'Bearbeiten',
    'Invoiceable orders': 'Auftrag an SAP',
    'Billing orders to SAP': 'Fakturierungsaufträge an SAP',
    'Generate order in SAP': 'Auftrag in SAP generieren',
    'Billing order in SAP': 'Fakturierungsaufträge in SAP',
    'Show Position History': 'Positionsverlauf anzeigen',
    'Refresh History': 'Verlauf aktualisieren',
    'Do you really want to delete this order?': 'Möchten Sie diese Bestellung wirklich löschen?',
    'Select user history': 'Benutzerhistorie auswählen',
    'All users history': 'Verlauf für alle Benutzer',
    'Settings': 'Einstellungen',
    'Add new': 'Neue hinzufügen',
    'Materials': 'Materialien',
    'Logs': 'Protokolle',
    'View Response': 'Antwort anzeigen',
    'View Request': 'Anfrage anzeigen',
    'Refresh Logs': 'Aktualisieren Sie die Protokolle',
    'Storage Location': 'Lagerraum',
    'Tax Class': 'Steuerklasse',
    'Download': 'Herunterladen',
    'Reports': 'Berichte',
    'Refresh Reports': 'Berichte aktualisieren',
    'Cost Code': 'Kostencode',
    'Costs': 'Kosten',
    'Amount': 'Menge',
    'Invoice SAP ID': 'Rechnungs-SAP-ID',
    'Delivery Note SAP ID': 'Lieferschein-SAP-ID',

    'Sort': 'Sortieren',
    'Rows per page:': 'Zeilen pro Seite:',
    'Sorry, no matching records found': 'Es wurden leider keine passenden Datensätze gefunden',
    'Search': 'Suche',
    'row(s) selected': 'Zeile(n) ausgewählt',
    'Next Page': 'Nächste Seite',
    'Previous Page': 'Vorherige Seite',

    'in': 'im',
    'for': 'zum',
    'matching operation canceled': 'Ausgleichsvorgang abgebrochen',
    'field': 'Feld',
    'has changed from': 'hat sich geändert von',
    'to': 'zu',
    'of': 'von',
    'value': 'Wert',
    'Record': 'Aufzeichnung',
    'created in': 'erstellt in',
    'updated in': 'aktualisiert in',
    'matched with': 'abgestimmt mit',
    'transferred to': 'übertragen auf',
    'locked by': 'eingesperrt von',
    'unlocked by': 'freigeschaltet von',

    'id': 'lfd. Nummer',
    'created_at': 'Datum',
    'order_date': 'Datum',
    'invoice_id': 'Invoice SAP ID',
    'canceled': 'storniert',
    'cost_center': 'Kostenstelle',
    'division': 'Sparte',
    'locked': 'gesperrt',
    'material_code': 'Material',
    'name': 'Kunde',
    'notes': 'Bemerkung',
    'sap_order_id': 'Auftrag',
    'delivery_note_id': 'Lieferschein-ID',
    'price': 'Preis',
    'locked_by': 'Gesperrt von',
    'relevant_for_sap': 'SAP-relevant',
    'unmatched_weight': 'unzugeordnete Menge',
    'unmatched weight': 'unzugeordnete Menge',
    'weight': 'Gewicht',
    'sap_id': 'SAP Id',
    'address': 'Adresse',
    'internal': 'Intern',
    'position_price': 'Gesamtpreis',
    'unit_price': 'Preis',
    'external_ref_number': 'Extern',
    'customer_sap_id': 'Kunden-SAP-ID',
    'created_by': 'Erstellt von',
    'affected_object': 'Betroffenes Objekt',
    'action': 'Aktion',
    'terms_of_payment': 'Zahlungsbedingungen',
    'comments': 'Bemerkungen',
    'order_type': 'Auftragsart',
    'order_no': 'Bestellnummer',
    'billing_attachment_text': 'Abrechnungsanhangstext',
    'vat_transaction_indicator': 'MwSt.-Indikator',
    'keep_an_eye_on_it': 'braucht Beachtung',
    'value_date': 'Valutadatum',

    'purchases': 'Ankauf',
    'sales': 'Verkauf',
    'sale_orders': 'Verkaufsaufträge',
    'purchase_orders': 'Kaufauftrag',

    'multiplier': 'Multiplikator',
    'price_unit': 'Preiseinheiten',
    'weight_unit': 'Gewichtseinheit',
    'color': 'Farbe',
    'material_number': 'Artikelnummer',
    'profits_and_losses': 'GuV',
    'inventory': 'Bestand',

    'login': 'Anmeldung',
    'call_type': 'Anrufart',
    'code': 'Code',
    'function': 'Funktion',
    'request': 'Anfordern',
    'response': 'Antwort',

    'download': 'Herunterladen',
    'filename': 'Berichtsname',

    'log': 'Protokollieren',
    'history': 'Verlauf',
    'features_availability': 'Funktionen Verfügbarkeit',
    'trading_process': 'Handelsprozess',
    'Log TD Errors': 'TD-Fehler protokollieren',
    'Log TD => SAP API call Errors': 'TD protokollieren => Fehler beim Aufruf der SAP API',
    'Log SAP => TD API call Errors': 'SAP protokollieren => TD-API-Aufruffehler',
    'Log ALL SAP => TD API calls': 'Protokolliere ALLE SAP => TD API Aufrufe protokollieren',
    'Log ALL TD => SAP API Calls': 'Protokolliere ALLE TD => SAP API Aufrufe',
    'Record to the history locking positions manually': 'Manuelles Aufnehmen in den Verlauf der gesperrten Positionen',
    'Record to the history locking positions during the match': 'Aufzeichnung der gesperrten Positionen während der Zuordnung',
    'Enable additional costs feature': 'Zusatzkostenfunktion aktivieren',
    'Enable delivery note feature': 'Lieferscheinfunktion aktivieren',
    'Lock all prev. months': 'Sperren Sie alle vorherigen Monate',
};
