

import { FormAction } from 'redux-form';
import { Epic, combineEpics } from 'redux-observable';
import {
    filter,
    map,
    mergeMap,
    delay
} from 'rxjs/operators';
import { isOfType } from 'typesafe-actions';
import * as SessionAction from '../actions/session';
import { RootAction } from '../store/types';
import { USER_SUBMIT_LOGIN_FORM, USER_SUBMIT_LOGOUT, SESSION_PING, USER_LOGED_IN_SUCCESS, ITERATE_HEARTBEAT } from '../data/constants';
import { SessionServiceClass } from '../services/login'
import { forkJoin } from 'rxjs';
import { getUserToken } from '../selectors';
import { getLoginFormValues } from '../selectors/index';
import { getSessionUser, getHeartbeatCount, getCredentialsBaseToken } from '../selectors/session';
import { setErrorMessage } from '../actions/app';
import * as DataAction from '../actions/data';
import { convertCredentialsToBase64 } from '../helpers/helper';

const SessionService = new SessionServiceClass();

const handleSubmitLoginForm: Epic<FormAction, RootAction> = (
    action$,
    store
) =>
    action$.pipe(
        filter(
            action =>
                (isOfType(USER_SUBMIT_LOGIN_FORM, action))
        ),
        mergeMap((action: FormAction) => {
            const loginData = getLoginFormValues(store.value);
            const baseToken = convertCredentialsToBase64(loginData);
            const login = SessionService.loginReques(
                { },
                baseToken
            );
            return forkJoin(login).pipe(
                mergeMap((response: any) => {
                    const res = response.pop();
                    if (res.status !== 'OK') {
                        return [
                            SessionAction.userLogedInErrorr(res.message),
                            DataAction.createRequestStatusUpdate("ERROR")
                        ];
                    }
                    if (!res.user) {
                        return [
                            SessionAction.userLogedInErrorr("Invalid authorisation"),
                            DataAction.createRequestStatusUpdate("ERROR")
                        ];
                    }
                    SessionService.storeSessionDataInSessionStorage({
                        authorizationToken: baseToken || '',
                        user: res.user || '',
                        role: res.role || '',
                    });
                    return [
                        SessionAction.setAuthorizationToken(baseToken),
                        SessionAction.userLogedInSuccess(res),
                        DataAction.createRequestStatusUpdate("OK"),
                    ];
                })
            );
        })
    );

const handleSubmitLogout: Epic<FormAction, RootAction> = (
    action$,
    store
) =>
    action$.pipe(
        filter(
            action =>
                (isOfType(USER_SUBMIT_LOGOUT, action))
        ),
        mergeMap((action: FormAction) => {
            const userToken = getUserToken(store.value);
            const baseToken = getCredentialsBaseToken(store.value);
            const login = SessionService.logoutReques({ }, baseToken)
            SessionService.storeSessionDataInSessionStorage({
                authorizationToken: '',
                user: '',
                role: '',
            });
            return forkJoin(login).pipe(
                map((response: any) => {
                    return SessionAction.userLogoedOut();
                })
            );
        })
    );
    
export default combineEpics(
    handleSubmitLoginForm,
    handleSubmitLogout,
);
